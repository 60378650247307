
import Vue from 'vue';
import MapComponent from '@point/map-component';
import gradstop from 'gradstop';
import NoMapData from '../charts/no-data/NoMapData.vue';
import MapCampaignSummaryController from './mapCampaignSummary/mapCampaignSummaryController.vue';
import EditModuleBtn from '../charts/buttons/editModuleBtn.vue';
import utils from '../../../util';

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'geofenceMap',
  components: { MapComponent, NoMapData, MapCampaignSummaryController, EditModuleBtn },
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
    componentHeight: Number,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      mapAdData: null,
      campaignDetails: null,
      loading: true,
      loadedValidData: true,
      mapReady: false,
      dataCacheKey: null,
      hoveredFence: null,
      exportBackgroundImage: null,
      mapId: null,
    };
  },
  computed: {
    mapRenderCacheKey(): string {
      return `_geofenceMap_${this.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_geofenceMap_${this.dataCacheKey}`;
    },
    hasRequiredData(): boolean {
      if (this.isExporting && this.componentConfig.pretendError) {
        return false;
      }
      return !!(this.dataCacheKey && this.mapAdData && Array.isArray(this.mapAdData) && this.mapAdData.length > 0);
    },
    mapTilerMapId(): string {
      if (this.componentConfig?.themes?.length === 1) {
        // if only one theme exist
        return utils.getMapId(this.componentConfig.themes[0]);
      }
      return 'hybrid';
      // 381cd47b-1090-4580-b7bb-7058f89205f5 -> https://cloud.maptiler.com/maps/381cd47b-1090-4580-b7bb-7058f89205f5/ -> Satellite SBG
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    onlySummary(): boolean {
      return this.componentConfig.onlySummary;
    },
    onlyMap(): boolean {
      return this.componentConfig.onlyMap;
    },
    demographicsOnly(): boolean {
      return this.componentConfig.customStyle === 'demographicsOnly';
    },
  },
  watch: {
    mapRenderCacheKey: {
      handler(key: string, old: string): void {
        if (old.indexOf('_null_') > 0) {
          return; // ignore the first mounted, before data is available
        }
        setTimeout(() => {
          const map = this.$refs[this.mapCacheKey]?.Get();
          if (map) {
            this.renderPolygons(true);
          }
        }, 50);
      },
    },
  },
  mounted() {
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid });
    // only initMap when data is loaded, there may be old data from previous mount
    setTimeout(() => {
      if (!utils.isWaitingOnData(this)) {
        this.load();
      }
    }, 1000);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.load, true);
  },
  beforeDestroy(): void {
    unwatchDataChanges();
  },
  methods: {
    load(): void {
      if (this.onlySummary) {
        this.loading = false;
        return;
      }

      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''} ${this.$route.viewCampaigns || ''}`;
      this.dataCacheKey = `${this.componentConfig.dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');

      if (utils.isWaitingOnData(this)) {
        this.loading = true;
        return;
      }

      this.loading = false;
      this.mapAdData = utils.adDataForKey(this, this.componentConfig.dataSource);
      if (!this.hasRequiredData && this.onlyMap) {
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        setTimeout(() => {
          this.$emit('rendered', { empty: true, check: 'geoFenceMap no hasRequiredData' });
        }, 10);
      }
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      if (!redrawing) {
        map.ZoomControlsOnRight();
      }

      this.mapReady = true;
      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderPolygons();
        }, 500);
      } else {
        // we're (probably) coming back to an already rendered map
        setTimeout(() => {
          this.renderPolygons(true);
        }, 50);
      }
      const mapDiv = document.getElementById('geoFenceMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.drawBackground();
      this.onMapMovedTimer = setTimeout(() => {
        // console.log('map end moved');
      }, 500);
    },
    drawBackground() {
      const mapEl = this.$refs[this.mapId]?.$el;
      const map = this.$refs[this.mapCacheKey]?.Get();
      const zoom = map.GetZoom() - 1; // why do we need the -1 ???
      const center = map.GetCenter();
      // const bounds = map.GetBounds();
      // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      var img = new Image();
      img.addEventListener(
        'load',
        () => {
          this.exportBackgroundImage = staticMapByCenter;
          tileBackground.style.display = 'none';
          setTimeout(() => {
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
            this.$emit('rendered', { empty: false });
          }, 1000);
        },
        false,
      );
      img.src = staticMapByCenter;
    },
    renderPolygons(redrawing: false): void {
      // when redrawing, the map is put back into the dom already rendered, but we need to re-hook its behaviors
      // so render without any animation

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!this.mapReady || !map || !map.host || !map.leaflet) {
        // console.log('map not ready');
        return;
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      let maxScore = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.mapAdData.forEach((d: any) => {
        if (d.Impressions > maxScore) {
          maxScore = d.Impressions;
        }
      });

      this.emitRendered(map, this.mapCacheKey);

      map.ClearMap();
      const hostLayer = map.CreateLayer('polygons');

      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      const maxPolygonLimit = this.isExporting ? 100 : 1000;
      if (this.mapAdData.length) this.mapAdData = this.mapAdData.slice(0, maxPolygonLimit);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.mapAdData.forEach((d: any, i: number) => {
        if (!d || !d.Lat || !d.Long) {
          // eslint-disable-next-line no-console
          console.log('mapAdData missing data', d);
        }
        const lat = parseFloat(d.Lat);
        const lon = parseFloat(d.Long);
        if (isNaN(lat) || isNaN(lon) || lat === 0 || lon === 0) {
          // eslint-disable-next-line no-console
          console.log('mapAdData bad data', d);
          return;
        }

        const score = d.Impressions / maxScore;
        const zIndex = Math.floor(1000 * score);
        const icon = map.AddDivToLayer({ className: 'fenceIcon', zIndexOffset: zIndex }, lat, lon, hostLayer);
        const color = gradient[Math.floor(score * 100.0)];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        let hovered = false;
        // icon._icon.style.background = color;
        // console.log('here', d.GeoFenceName, d.Impressions, score, x, color, icon);
        if (redrawing) {
          if (icon && icon._icon) {
            icon._icon.style.opacity = 0.7 + 0.3 * score;
            icon._icon.style.background = color;
          }
        } else {
          setTimeout(() => {
            if (icon && icon._icon) {
              icon._icon.style.opacity = 0.7 + 0.3 * score;
              icon._icon.style.background = color;
            }
          }, 1500 + 10 * i);
        }
        icon
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredFence = {
              name: d?.name || d.GeoFenceName?.replace('Ő', "'").replace('Đ', '-').replace('Ę', ''),
              impressions: this.formatValue(d.Impressions),
              clicks: this.formatValue(d.Clicks),
            };
            icon._icon.style.opacity = 1;
            hovered = true;
            setTimeout(() => {
              if (!hovered) {
                return;
              }
              const template = this.$refs.mapToolTip?.innerHTML || '';
              layerPopup = map.CreatePopup(template, e.latlng, { closeButton: false, offset: [2, 10] });
            }, 250);
          })
          .on('mouseout', () => {
            hovered = false;
            icon._icon.style.opacity = 0.7 + 0.3 * score;
            if (layerPopup) {
              map.ClosePopup(layerPopup);
              layerPopup = null;
            }
          });
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);
      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (!this.onlyMap) {
        paddingOptions = { ...paddingOptions, paddingTopLeft: [400, 20], paddingBottomRight: [20, 20] };
      }
      if (map.initialized) {
        setTimeout(() => {
          if (!this.mapAdData.length) {
            map.SetView([40, -100], 4);
          } else {
            map.FitAllLayers({ force: true, duration: 0.3, ...paddingOptions });
          }
        }, 10);
      } else {
        setTimeout(() => {
          if (!this.mapAdData.length) {
            map.SetView([40, -100], 4);
          } else {
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
          }
        }, 10);
      }
    },
    formatValue(value): string {
      if (value === undefined || value === null) return '0';
      if (value < 1) return value.toString(); // handle decimals
      return utils.formatNumberWithCommas(value);
    },
    drawBackground() {
      const mapEl = this.$refs[this.mapId]?.$el;
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) {
        // eslint-disable-next-line no-console
        console.error('DMAZipMap, can not export, no map', retries);
        return;
      }
      const zoom = Math.round(map.GetZoom()) - 1;
      const center = map.GetCenter();
      // const bounds = map.GetBounds();
      // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      var img = new Image();
      img.addEventListener(
        'load',
        () => {
          this.exportBackgroundImage = staticMapByCenter;
          // tileBackground.style.display = 'none';
          setTimeout(() => {
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: false });
            this.hasRendered = true;
          }, 1000);
        },
        false,
      );
      img.src = staticMapByCenter;
      this.fullyRendered = 2;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(map: any, mapId: string, retries = 5): void {
      if (this.isExporting) {
        setTimeout(() => {
          const mapEl = this.$refs[mapId]?.$el;
          if (!mapEl) {
            // eslint-disable-next-line no-console
            console.error('can not export, no map element');
            if (retries > 0) {
              setTimeout(() => {
                this.emitRendered(map, mapId, retries - 1);
              }, 500);
            } else {
              setTimeout(() => {
                this.$emit('rendered', { empty: true, check: 'geoFenceMap no map element' });
              }, 10);
            }
            return;
          }
          const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
          if (!tileBackgroundList || tileBackgroundList.length === 0) {
            // eslint-disable-next-line no-console
            console.error('GeoFenceMap, can not export, no map background');
            if (retries > 0) {
              setTimeout(() => {
                this.emitRendered(map, mapId, retries - 1);
              }, 500);
            } else {
              setTimeout(() => {
                this.$emit('rendered', { empty: true, check: 'geoFenceMap no map background' });
              }, 10);
            }
            return;
          }
          const tileBackground = tileBackgroundList[0] as HTMLElement;

          const zoom = map.GetZoom() - 1; // why do we need the -1 ???
          const center = map.GetCenter();
          // const bounds = map.GetBounds();
          // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
          const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
          var img = new Image();
          img.addEventListener(
            'load',
            () => {
              this.exportBackgroundImage = staticMapByCenter;
              tileBackground.style.display = 'none';
              setTimeout(() => {
                this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
                this.$emit('rendered', { empty: false });
              }, 1000);
            },
            false,
          );
          img.src = staticMapByCenter;
        }, 1000);
      } else {
        setTimeout(() => {
          this.$emit('rendered', { empty: false });
        }, 1000);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      this.$emit('rendered', ctx);
    },
  },
});
