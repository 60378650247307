/* eslint-disable no-unused-vars */
import { Conversions } from '@point/utility-classes';
export interface StateType {
  conversions: Conversions | null;
  conversionsError: string | null;
  isConversionsLoading: boolean;
  conversionsCache: null | string;
  isConversionsCached: boolean;

  dailyPerformance: object | null;
  dailyPerformanceError: string | null;
  isDailyPerformanceLoading: boolean;
}

export enum Resolution {
  day = 'day',
  week = 'week',
  month = 'month',
}

export type ConversionsPayload = {
  daterange: string;
  advertiserId: string;
  campaignDashboardIds: string;
  resolution: Resolution;
  startdate?: string;
  enddate?: string;
};

export type DailyPerformancePayload = {
  daterange: string;
  advertiserId: string;
  campaigns: { id: string; type: string }[];
  startdate?: string;
  enddate?: string;
};
