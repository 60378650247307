
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import { format, parse } from 'date-fns';
import { EChartsOption, LegendOption } from 'echarts/types/dist/shared';
import SideSummary from '../overviews/sideSummary.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';
// this should be a module import, no cross linking across packages!
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { C360Icon } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericLineChart',
  components: { NoChartData, SideSummary, Tooltips, ExportDownloadBtn, EditModuleBtn, C360Icon },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'subTitle',
    'icon',
    'chartType',
    'commonTweaks',
    'dataSource',
    'dataKey',
    'theme',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
    'isGetNewData',
    'separateData',
    'hideTitle',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    canvasWidth: string;
    canvasHeight: string;
    legendSelected: LegendOption | null;
    selectedLine: string | null;
    dropdownMenuOpen: boolean;
    menuLegendKeys: string[];
    reducedCanvas: string[];
  } => ({
    chartInstance: undefined,
    canvasWidth: 'auto',
    canvasHeight: '450px',
    legendSelected: null,
    selectedLine: null,
    dropdownMenuOpen: false,
    menuLegendKeys: [],
    reducedCanvas: [
      'simpgeofencePerformanceStoreVisitsLineChart',
      'broadcastDailyPerformanceLineChart',
      'ottPerformanceByDayOfWeekLineChart',
      'gamvideoDailyPerformanceLineChart',
      'gamdisplayDailyPerformanceLineChart',
    ],
  }),
  watch: {
    chartData: {
      immediate: true,
      handler(): void {
        this.initChart();
        // hide right away if incorrect feed source
        // show if in edit mode whatever the data is
        // show if there is data, hide if not + hideIfNoData in component config is true
        if (this.hasCustomizedFeedSource && !this.validFeedSource) {
          this.$emit('set-display', false);
          return;
        }
        if (this.$store.state.layoutEditor.editMode) {
          this.$emit('set-display', true);
          return;
        }
        if (!this.chartData.length && this.componentConfig.hideIfNoData) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
    '$store.state.customer.theme': 'initChart',
    // custimaztion changes
    showOneLine: {
      handler(): void {
        this.initChart();
      },
    },
    chartColors: {
      handler(): void {
        this.initChart();
      },
    },
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
    lineSeriesKeys: {
      handler(): void {
        this.menuLegendKeys = this.lineSeriesKeys;
      },
    },
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    // setTimeout(() => {
    //   console.log(this.componentConfig, this.componentConfig.lineSeriesKeys);
    // }, 500);
    if (this.componentConfig.selectedLineSeriesKey) {
      this.selectedLine = this.componentConfig.selectedLineSeriesKey;
    } else if (this.componentConfig.lineSeriesKeys?.length > 0) {
      this.selectedLine = this.componentConfig.lineSeriesKeys[0];
    }
    this.menuLegendKeys = this.lineSeriesKeys;
    this.initChart();
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    availableOptions(): string[] {
      if (this.$store.state.customer.currentNavTab === 'broadcast')
        return [
          'Overnight',
          'Early Morning',
          'Daytime',
          'Early Fringe',
          'Early News',
          'Prime Access',
          'Prime',
          'Late Fringe',
        ];
      return ['Morning', 'MidDay', 'Afternoon', 'Night', 'Overnight'];
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData) || this.isPrinting) {
        return false;
      }
      return true;
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
    tooltip(): Array<Tooltip> | null {
      // DASH-4391: hide 'Impression' tooltip for perfByDaypart only on simplifi
      if (
        this.isSimplifi &&
        this.selectedLine?.toLowerCase() === 'impressions' &&
        this.componentConfig?.cid?.toLowerCase().includes('performancebydaypart')
      )
        return null;

      // allows an override of the tooltip configured in the layout file
      if (this.componentConfig.tooltips) return this.componentConfig.tooltips;

      // otherwise get tooltip based on selected metric
      let activeMetric = this.selectedLine?.toLowerCase();
      // console.log('activeMetric', activeMetric);
      if (this.componentConfig?.cid?.includes('ott') && activeMetric === 'conversions') {
        activeMetric = 'conversionsott';
      }

      // convert to the expected array
      return [utils.getTooltipsFromMetric(activeMetric)] || null;
    },
    containerClass(): string[] {
      const classes = this.isExporting ? ['exporting-container'] : ['ml-4'];
      if (this.sideMetricsConfig) {
        classes.push('has-side-metrics');
        if (!this.isExporting) {
          classes.push('container');
        }
      }

      return classes;
    },
    isDataNotEnrichedWithInnovid(): boolean {
      // innovid xp data is populated only to ctv daily perf and ctv perf by day of week - check only them
      // do not show lines if original data is not enriched with innovid data
      if (!['ottDailyPerformanceLineChart', 'ottPerformanceByDayOfWeekLineChart'].includes(this.componentConfig?.cid)) {
        return false;
      }

      // at least one dot on chart doesn't have innovid data or all data is zeroes
      return (
        this?.sourceData?.some(obj => obj?.ResponseNumber === undefined) ||
        this?.sourceData?.every(obj => obj?.ResponseNumber === 0)
      );
    },
    areConversionsZero(): boolean {
      return !this?.sourceData?.some(obj => obj?.Conversions);
    },
    getInterval(): number {
      if (this.componentConfig.xAxisIntervalCount) {
        if (!this.chartData[0]) return 0;
        return Math.floor(this.chartData[0].length / this.componentConfig.xAxisIntervalCount);
      } else return this.componentConfig.xAxisShowAll ? 0 : 1;
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      // DASH-X: hide empty series charts for printing
      const isPrinting = this.$route.query.print === 'true';
      if (isPrinting && this.componentConfig.hideEmptySeriesKeys) {
        return this.chartData[1] && this.chartData[1].filter(x => x > 0).length;
      }
      return this.chartData[0] && this.chartData[0].length > 0;
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasStyle(): any {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
    additionalMetrics(): [string] {
      return this?.componentConfig?.additionalMetrics || [];
    },
    hasAdditionalData(): boolean {
      // DASH-4704: ignore multilineDailyPerformance chart when filtering OTT/Innovid metrics
      if (['ottDailyPerformanceLineChart', 'ottPerformanceByDayOfWeekLineChart'].includes(this.componentConfig?.cid)) {
        return !(
          this?.sourceData?.some(obj => obj?.ResponseNumber === undefined) ||
          this?.sourceData?.every(obj => obj?.ResponseNumber === 0)
        );
      }
      return this?.componentConfig?.additionalMetrics && this?.sourceData?.some(row => row?.isEnriched);
    },
    sourceData() {
      let data;

      if (this.isGetNewData) {
        return this.separateData;
      }
      // check for indivdual data
      const hasIndivdualCampaignData = this.tweaks?.includes('indivdual');
      if (hasIndivdualCampaignData) {
        data = utils.adDataForKey(this, `${this.dataSource}Campaign`);
        if (!Array.isArray(data)) {
          data = utils.adDataForKey(this, this.dataSource);
        }
      } else {
        data = utils.adDataForKey(this, this.dataSource);
      }
      return data;
    },
    limitChartsEnoughData(): boolean {
      if (!Array.isArray(this.sourceData)) {
        return false;
      }
      return this.sourceData.length === 1;
    },
    lineSeriesKeys(): string[] {
      let lineSeriesKeys = this.filterOutKeys(this.componentConfig.lineSeriesKeys);
      if (!Array.isArray(lineSeriesKeys)) {
        if (typeof lineSeriesKeys === 'string') {
          lineSeriesKeys = [lineSeriesKeys];
        } else {
          lineSeriesKeys = [];
        }
      }
      const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
      if (hideEmptySeriesKeys) {
        const rows = this.sourceData;
        if (!rows) return;
        // remove keys that don't have any data
        hideEmptySeriesKeys.forEach((k: string) => {
          if (!rows.find(x => !!x[k])) {
            lineSeriesKeys = lineSeriesKeys.filter(x => x !== k);
          }
        });
      }

      // order to make selected line last so non-selected dont overlap
      if (lineSeriesKeys.length > 1 && this.selectedLine) {
        const keys: string[] = [this.selectedLine];
        lineSeriesKeys.forEach((key: string) => {
          if (key !== this.selectedLine) keys.push(key);
        });
        lineSeriesKeys = keys;
      }
      return lineSeriesKeys;
    },
    chartConfig(): EChartsOption {
      let lineColor, textColor;
      if (this.Theme.config.lineCharts?.style === 'minimal') {
        lineColor = '#fff';
        textColor = '#000';
      } else {
        lineColor = '#000';
        textColor = lineColor;
      }
      if (this.isExporting) {
        lineColor = '#333';
      }

      let colorset = this.colorset;
      if (this.componentConfig.useChartColorIndex > -1) {
        colorset = [this.colorset[this.componentConfig.useChartColorIndex]];
      }

      // DASH-5027: for some bizzare reason some linecharts are taking more places than the others, though
      // they don't have any difference in the config. this is a temporary solution to reduce the canvas size for those charts.
      const shouldCanvasBeReduced = !this.isExporting && this.reducedCanvas.includes(this.componentConfig?.cid);
      const cfg: EChartsOption = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
          show: true,
          formatter: function (params): string | null {
            // formatting the tooltip to handle for percent values and match current style.
            try {
              const tooltipArray: Array<string> = [];
              tooltipArray.push(`${params[0].name}<table>`);
              params.forEach(tipdata => {
                const { seriesName, marker, data } = tipdata;
                const value = seriesName === 'VCR' ? `${data}%` : data;
                tooltipArray.push(
                  `<tr><td>${marker} ${seriesName}:</td> <td style="text-align:right"><b>${value}</b></td></tr>`,
                );
                return tooltipArray;
              });
              tooltipArray.push('</table>');
              return tooltipArray.join('');
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('tooltip formatter', exp, params);
            }
            return null;
          },
        },
        title: {
          show: true,
          textStyle: {
            color: textColor,
            fontFamily: 'sans-serif',
          },
        },
        grid: this.isExporting
          ? {
              containLabel: true,
              left: this.componentConfig.canvasPosition?.left || 10,
              top: this.componentConfig.canvasPosition?.top || 10,
              right: this.componentConfig.canvasPosition?.right || 10,
              bottom: this.componentConfig.canvasPosition?.bottom || 10,
              height: this.componentConfig.canvasPosition?.height,
              width: this.componentConfig.canvasPosition?.width,
            }
          : {
              top: 20,
              left: 20,
              width: shouldCanvasBeReduced ? '96%' : '100%',
              containLabel: true,
            },
        xAxis: {
          show: this.isExporting || this.Theme.config.lineCharts?.xAxis?.show !== false,
          data: this.chartData?.[0],
          type: 'category',
          boundaryGap: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          axisTick: {
            show: this.isExporting,
            alignWithLabel: true,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          max: parseInt(this.componentConfig.xAxisMaxItems) || 'dataMax',
          axisLabel: {
            show: true,
            color: textColor,
            interval: this.getInterval,
            rotate: typeof this.componentConfig.xAxisRotate !== 'undefined' ? this.componentConfig.xAxisRotate : 0,
          },
        },
        color: colorset,
        height: '360px',
        width: '100%',
      };

      const lineSeriesKeys = this.filterOutKeys(this.lineSeriesKeys);

      if (Array.isArray(this.chartData) && this.chartData.length) {
        // line series
        cfg.series = lineSeriesKeys.reduce((lines: object[], key: string, index: number) => {
          const line = {
            type: 'line',
            selectMode: false,
            animation: !this.isExporting,
            name: this.getLegendKeyName(key),
            yAxisIndex: index,
            data: this.chartData[index + 1],
            smooth: true,
            symbol: this.chartData[0].length > 1 ? 'none' : 'emptyCircle',
            zlevel: key === this.selectedLine ? 3 : 1,
          };

          if (this.showOneLine && key === this.selectedLine) {
            lines.push(line);
          } else if (!this.showOneLine) {
            lines.push(line);
          }
          return lines;
        }, []);
      }

      // y-axis
      cfg.yAxis = lineSeriesKeys.reduce((lines: object[], key: string) => {
        lines.push({
          show: key === this.selectedLine,
          type: 'value',
          splitLine: {
            show: true,
          },
          scale: !!this.componentConfig.scale,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: textColor,
            padding: [0, 0, 0, 0],
            formatter: function (value) {
              try {
                if (key === 'CompletionRate' || key === 'ResponseRate' || key === 'ImpressionPercent') {
                  // Add perecent to completion rate.
                  return `${value.toFixed(2)}%`;
                }
                if (typeof value === 'number') {
                  return utils.formatNumberWithCommas(value); // .toLocaleString();
                }
                return value;
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('xaxislabel formatter', exp, value);
              }
              return value;
            },
          },
        });
        // console.log('lines are', lines);
        return lines;
      }, []);

      // tooltip
      if (this.componentConfig.tooltipType && this.componentConfig.tooltipType !== 'default') {
        cfg.tooltip = {
          formatter: this.tooltip,
          trigger: 'axis',
          show: true,
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
        };
      }

      if (
        [
          'ottPerformanceByDayOfWeekLineChart',
          'ottDailyPerformanceLineChart',
          'tv2ottResponsesByWeekLineAndBarChart',
        ].includes(this.componentConfig.cid)
      ) {
        cfg.tooltip = {
          formatter: function (params): string | null {
            // const mappedName = utils.headerNamesMap(params.seriesName);
            // const value = utils.formatNumberWithCommas(params.data[params.seriesIndex + 1]);
            const formattedTooltipText = params
              .map(param => {
                let formattedData;
                switch (param.seriesName) {
                  case 'Response Rate':
                  case 'VCR':
                    formattedData = `${Number(param.data).toFixed(2)}%`;
                    break;
                  case 'Impressions':
                  case 'Viewing Hours':
                    formattedData = utils.formatNumberWithCommas(param.data);
                    break;
                  default:
                    formattedData = param.data;
                }

                return `${param.marker} ${param.seriesName}: <strong>${formattedData}</strong><br>`;
              })
              .join('');

            return `${params[0].name}<br>${formattedTooltipText}`;
          },
          trigger: 'axis',
          show: true,
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
        };
      }

      // legend keys
      const legendKeys: string[] = [];
      lineSeriesKeys?.forEach((key: string) => {
        legendKeys.push(this.getLegendKeyName(key));
      });

      cfg.legend = {
        show: this.componentConfig.hideLegend !== true,
        data: legendKeys,
        selectedMode: false,
        icon: 'circle',
        bottom: 0,
        left: 10,
        textStyle: {
          fontWeight: 600,
        },
      };

      if (this.isExporting) {
        if (typeof this.componentConfig?.legend?.orient !== 'undefined') {
          cfg.legend.orient = this.componentConfig.legend.orient;
        }
        if (typeof this.componentConfig?.legend?.align !== 'undefined') {
          cfg.legend.align = this.componentConfig.legend.align;
        }
        if (typeof this.componentConfig?.legend?.left !== 'undefined') {
          cfg.legend.left = this.componentConfig.legend.left;
        }
        if (typeof this.componentConfig?.legend?.top !== 'undefined') {
          cfg.legend.top = this.componentConfig.legend.top;
        }
      }

      return cfg;
    },
    colorset(): string[] {
      const colors: string[] = [];
      const greyColors = ['#c6c6c6', '#5f5e5e', '#a6a6a6', '#000000', '#ff0000'];
      let greyColorIndex = 0;
      const colorIndex = this.componentConfig.lineSeriesKeys?.indexOf(this.selectedLine);
      let colorArray = this.Theme.config.chartColors;
      if (this.componentConfig.chartColors) {
        // use configurability override if defined
        colorArray = this.componentConfig.chartColors;
      }

      let lineColor = colorArray[colorIndex] || '#1e5380';
      if (this.componentConfig.chartColorIndex) {
        // a way to override color from layout config
        lineColor = colorArray[this.componentConfig.chartColorIndex];
      }

      // we need to look at the computed lineSeriesKeys for web
      this.lineSeriesKeys.forEach((key: string) => {
        if (!this.showOneLine && key !== this.selectedLine) {
          const grey = greyColors[greyColorIndex];
          colors.push(grey);
          greyColorIndex += 1;
        } else colors.push(lineColor);
      });

      return colors;
    },
    tweaks(): string[] {
      if (!this.componentConfig.commonTweaks) {
        return [];
      }
      if (Array.isArray(this.componentConfig.commonTweaks)) {
        return this.componentConfig.commonTweaks;
      }
      return [this.componentConfig.commonTweaks];
    },
    chartClass(): string {
      let css = 'generic-line-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      return css;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      /*
      this method returns an array of arrays where the first item is the bottom axis and additional arrays are line chart values
      An example of return values:
        [
          ['monday', 'tuesday'],
          ['12', '15'],
          ['x', 'y']
        ]
      */

      let rows = this.sourceData;
      if (!rows || !Array.isArray(rows) || rows.length === 0) {
        return [];
      }

      // check for hours for sorting.
      const isHours = this.tweaks?.includes('hourbackfill');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rows = rows.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data
      const sortedRows = rows.sort(function (a, b) {
        const dateA = new Date(a.Date).getTime();
        const dateB = new Date(b.Date).getTime();
        if (isHours) return dateA > dateB ? -1 : 1;
        return dateA > dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
      });

      if (!this.tweaks.includes('dontFillZeros')) {
        // we will fill in missing dates with empty data;
        const firstDate = sortedRows[0].Date;
        const lastDate = sortedRows[sortedRows.length - 1].Date;
        // 86400000 ms in a day
        if (lastDate && firstDate) {
          const dates = [...Array(Date.parse(lastDate) / 86400000 - Date.parse(firstDate) / 86400000 + 1).keys()].map(
            k => new Date(86400000 * k + Date.parse(firstDate)).toISOString().slice(0, 10),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: any[] = []; // empty data array
          for (let i = 0, j = 0; i < dates.length; i++) {
            // if the date in the data matches the date in the empty data array, copy the values and increment j
            // otherwise use empty values and do not increment j
            result[i] = { Date: dates[i] };
            if (dates[i] === sortedRows[j].Date) {
              this.componentConfig.lineSeriesKeys?.forEach((key: string) => {
                result[i][key] = sortedRows[j][key];
              });
              j += 1;
            } else {
              this.componentConfig.lineSeriesKeys?.forEach((key: string) => {
                const zeroValue = this.usesPercent(key) ? '0.0%' : 0;
                result[i][key] = zeroValue;
              });
            }
          }
          rows = result;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any[] = [];

      if (this.tweaks.includes('daybackfill')) {
        // xAxis
        data.push(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);

        // yAxis
        this.lineSeriesKeys.forEach(() => {
          data.push([]); // push an empty array for each key (aka each line series)
        });

        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].forEach((d: string) => {
          const day = rows.find((x: { Day: string }) => x.Day === d);
          this.lineSeriesKeys.forEach((key: string, index: number) => {
            if (day) {
              if (key === 'ClickThrough') {
                // this.ctrUsesPercent = day[key].split('').includes('%');
                data[index + 1].push(day[key].slice(0, day[key].length - 1));
              } else {
                data[index + 1].push(day[key]);
              }
            } else {
              data[index + 1].push(0);
            }
          });
        });
      } else if (this.tweaks.includes('daypartbackfill') && !this.isSimplifi) {
        // xAxis
        data.push([]);

        // yAxis
        this.lineSeriesKeys.forEach(() => {
          data.push([]); // push an empty array for each key (aka each line series)
        });
        const parts: object[] = [];
        this.availableOptions.forEach(dp => {
          data[0].push(dp);
          const daypart = rows.find((x: { Daypart: string }) => x.Daypart === dp);
          if (daypart) {
            this.lineSeriesKeys.forEach((key: string, index: number) => {
              if (key === 'ImpressionPercent') {
                data[index + 1].push(daypart[key].slice(0, daypart[key].length - 1));
              } else if (daypart) {
                data[index + 1].push(daypart[key]);
              } else {
                data[index + 1].push(0);
              }
            });
          }
        });
        rows = parts;
      } else if (this.tweaks.includes('daypartbackfill') && this.isSimplifi) {
        // xAxis
        data.push(['12am to 4am', '4am to 8am', '8am to 12pm', '12pm to 4pm', '4pm to 8pm', '8pm to 12am']);

        // yAxis
        this.lineSeriesKeys.forEach(() => {
          data.push([]); // push an empty array for each key (aka each line series)
        });

        ['12am to 4am', '4am to 8am', '8am to 12pm', '12pm to 4pm', '4pm to 8pm', '8pm to 12am'].forEach(
          (d: string) => {
            const daypart = rows.find((x: { Daypart: string }) => x.Daypart === d);
            this.lineSeriesKeys.forEach((key: string, index: number) => {
              if (daypart) {
                if (key === 'ImpressionPercent') {
                  data[index + 1].push(daypart[key].slice(0, daypart[key].length - 1));
                } else {
                  data[index + 1].push(daypart[key]);
                }
              } else {
                data[index + 1].push(0);
              }
            });
          },
        );
      } else if (isHours) {
        // xAxis
        data.push([]);

        // yAxis
        this.lineSeriesKeys.forEach(() => {
          // push an empty array for each key (aka each line series)
          data.push([]);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows.forEach((x: any) => {
          data[0].push(utils.formatHour(parseInt(x.Hour, 10), false));
          this.lineSeriesKeys.forEach((key: string, index: number) => {
            data[index + 1].push(x[key]);
          });
        });
        // rows = rows.map((x: any) => {
        //   if (x.Hour) {
        //     const Hour = utils.formatHour(parseInt(x.Hour, 10), false);
        //     return { ...x, Hour };
        //   }
        //   return '';
        // });
      } else if (this.tweaks.includes('datebackfill')) {
        rows = utils.sortByProperty(rows, 'Date');
        data.push(
          ...rows.reduce(
            (allRows, row) => {
              // set the date to the bottom (x) axis
              // input format is: 2020-02-01
              const formattedDate = format(parse(row.Date.replace(/\//g, '-'), 'yyyy-MM-dd', new Date()), 'MMM dd yy');
              allRows[0].push(formattedDate);

              // set all other properties to incrementing index
              this.lineSeriesKeys.forEach((key: string, index: number) => {
                if (
                  key === 'ClickThrough' ||
                  key === 'CompletionRate' ||
                  key === 'CVR' ||
                  key === 'VCR' ||
                  key === 'LinkClickThrough' ||
                  key === 'ImpressionPercent'
                ) {
                  if (row[key]) {
                    allRows[index + 1].push(row[key].slice(0, row[key].length - 1));
                  } else {
                    allRows[index + 1].push(0);
                  }
                } else {
                  if (row[key]) {
                    allRows[index + 1].push(row[key]);
                  } else {
                    allRows[index + 1].push(0);
                  }
                }
              });

              return allRows;
            },
            [
              // empty array for Date
              [],
              // get empty arrays for all properties to display on chart
              ...this.lineSeriesKeys.reduce(all => {
                all.push([]);
                return all;
              }, []),
            ],
          ),
        );
      }
      return data;
    },
    totalCount(): string | null {
      if (this.componentConfig.total) {
        if (this.isExporting && this.exportData) {
          const sectionKey = utils.dataKeyBySectionIdMap(this.exportData.tab);
          const data = utils.adDataForKey(this, `${sectionKey}Total`);
          return utils.formatNumberWithCommas(data[this.componentConfig.total]);
        }
        const sectionKey = utils.dataKeyBySectionIdMap(this.$store.state.customer.currentSection.id);
        const data = utils.adDataForKey(this, `${sectionKey}Total`);
        return utils.formatNumberWithCommas(data[this.componentConfig.total]);
      }
      return null;
    },
    isMultiLine(): boolean {
      return this.lineSeriesKeys.length > 1;
    },
    sideMetricsConfig(): object | null {
      const { sideTotalMetrics } = this.componentConfig;
      if (sideTotalMetrics && sideTotalMetrics.metrics?.length && sideTotalMetrics.dataSource) {
        return this.componentConfig.sideTotalMetrics;
      }
      return null;
    },
    showOneLine(): boolean {
      return this.componentConfig.showOneLine;
    },
    chartColors(): boolean {
      return this.componentConfig.chartColors;
    },
    breakpoints(): boolean {
      return this.componentConfig.breakpoints;
    },
    isSimplifi(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('SIMPLIFI');
    },
    noConversionsData(): boolean {
      return this?.sourceData?.length > 0 && this?.sourceData?.some(row => row.Conversions);
    },
    feedSources(): string[] {
      return utils.feedSources(this);
    },
    hasCustomizedFeedSource(): boolean {
      return this.componentConfig?.feedSource?.length || this.componentConfig?.feedSourceToExclude?.length;
    },
    validFeedSource(): boolean {
      if (!this.feedSources) return true;
      if (this.componentConfig?.feedSourceToExclude?.length) {
        return !this.componentConfig.feedSourceToExclude.some(item => this.feedSources.includes(item));
      }
      if (this.componentConfig?.feedSource?.length) {
        return this.componentConfig.feedSource.some(item => this.feedSources.includes(item));
      }
      return false;
    },
  },
  methods: {
    initChart(): void {
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      try {
        setTimeout(() => {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          this.chartInstance = ECharts.init(this.$refs.canvas);
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const c = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              c.on('finished', () => {
                if (
                  (this.isDataNotEnrichedWithInnovid &&
                    (this?.selectedLine === 'ResponseNumber' || this?.selectedLine === 'ResponseRate')) ||
                  (this.areConversionsZero && this?.selectedLine === 'Conversions')
                ) {
                  this.$emit('rendered', { empty: true });
                }
                this.$emit('rendered', { empty: false });
              });
              c.on('rendered', () => {
                if (
                  (this.isDataNotEnrichedWithInnovid &&
                    (this?.selectedLine === 'ResponseNumber' || this?.selectedLine === 'ResponseRate')) ||
                  (this.areConversionsZero && this?.selectedLine === 'Conversions')
                ) {
                  this.$emit('rendered', { empty: true });
                }
                this.$emit('rendered', { empty: false });
              });
            }
            c.setOption(this.chartConfig, true);
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        }, 10);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('initChart', error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
    getLegendKeyName(key: string): string {
      let name;
      switch (key) {
        case 'Impressions':
          name = key;
          break;
        case 'Hours':
          name = 'Viewing Hours';
          break;
        case 'ClickThrough':
          name = `CTR`;
          break;
        case 'CompletionRate':
          name = `VCR`;
          break;
        case 'GeoRetargetingImpressions':
          name = `Retargeting Imps`;
          break;
        case 'GeoBehaviorImpressions':
          name = `Behavioral Imps`;
          break;
        case 'GeoCookieImpressions':
          name = `Historical Imps`;
          break;
        case 'GeoFencingImpressions':
          name = `Geofencing Imps`;
          break;
        case 'GeoVisits':
          name = `Store Visits`;
          break;
        case 'VC100':
          name = '100% Viewed';
          break;
        default:
          name = utils.headerNamesMap(key);
          break;
      }
      return name;
    },
    selectLine(value: string): void {
      if (value !== this.selectedLine) {
        this.selectedLine = value;
        this.initChart();
      }
    },
    usesPercent(key: string): boolean {
      return (
        key === 'ClickThrough' ||
        key === 'CompletionRate' ||
        key === 'CVR' ||
        key === 'VCR' ||
        key === 'LinkClickThrough' ||
        key === 'ImpressionPercent'
      );
    },
    getTableData(): void {
      let headers: string[] = [];
      if (this.componentConfig.exportTweaks?.addDayOfTheWeekColumn) {
        headers.push('Day');
      } else {
        headers.push('n/a'); // can we know?
      }
      const lineSeriesKeys = this.filterOutKeys(this.componentConfig.lineSeriesKeys);
      headers = [...headers, ...lineSeriesKeys];

      // loop over chartData[0], those are the x axis values
      const data = [];
      if (Array.isArray(this.chartData) && Array.isArray(this.chartData[0])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.chartData[0].forEach((key: any, index: number) => {
          const row = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.chartData.forEach((serie: any[]) => {
            const val = serie[index];
            if (typeof val === 'string') {
              row.push(val.trim());
            } else {
              row.push(val);
            }
          });
          data.push(row);
        });
      }
      // console.log('line chart export', headers, data);
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
    filterOutKeys(lineSeriesKeys): Array<string> {
      // filter out InnovidXP metrics for OTT w/out InnovidXP data
      // filter out CVR, Conversions for non-Xandr module
      if (!this.hasAdditionalData) {
        lineSeriesKeys = lineSeriesKeys.filter(key => !this.additionalMetrics.includes(key));
      }
      if (!this.noConversionsData) {
        lineSeriesKeys = lineSeriesKeys.filter(key => !['Conversions', 'CVR', 'CVR %'].includes(key));
      }

      // TODO: temporary solution for PROD-8117, resolve on config level
      if (
        this.isExporting &&
        this.componentConfig?.cid === 'ottDailyPerformanceLineChart' &&
        this?.exportData?.exportLayoutName?.includes('xls')
      ) {
        const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
        if (hideEmptySeriesKeys) {
          const rows = this.sourceData;
          if (!rows) return;
          // remove keys that don't have any data
          hideEmptySeriesKeys.forEach((k: string) => {
            if (!rows.find(x => !!x[k])) {
              lineSeriesKeys = lineSeriesKeys.filter(x => x !== k);
            }
          });
        }
      }
      return lineSeriesKeys;
    },
  },
});
