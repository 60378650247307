
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import TableUi from './tableUI.vue';
import { Campaign } from '../../../../types/campaign';

/*
todo:
topOptions -> if we want a menu in the top right
parsevalues

headers:
array of { text: "col title", align: "left", sortable: true, value: "data property name" }
*/
function getBaseLog(x: number, y: number): number {
  return Math.log(y) / Math.log(x);
}

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericTable',
  components: { NoChartData, TableUi },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'topOptions',
    'headers',
    'commonTweaks',
    'dataSource',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'iconOverride',
    'componentHeight',
    'tableDataProp',
    'loadingProp',
  ],
  data: (): {
    filterNumber: number;
    categorySelected: string;
    categories: Array<string>;
    gtltSelected: string;
    gtlt: Array<string>;
    renderKey: Array<number>;
    selectedTacticFilter: string | null;
    isFilteredByTactic: boolean;
  } => ({
    filterNumber: 0,
    categorySelected: '',
    categories: [],
    gtltSelected: 'greater',
    gtlt: ['greater', 'less'],
    renderKey: [5],
    selectedTacticFilter: null,
    isFilteredByTactic: false,
  }),
  computed: {
    loading(): boolean {
      if (this.loadingProp !== null && this.loadingProp !== undefined) return this.loadingProp;
      return utils.isWaitingOnData(this);
    },
    hasAdditionalData(): boolean {
      return this?.componentConfig?.additionalMetrics && this?.tableDataSource?.some(row => row?.isEnriched);
    },
    additionalMetrics(): [string] {
      return this?.componentConfig?.additionalMetrics || [];
    },
    componentColumns(): string[] {
      if (this.componentConfig.fallbackTable && !this.initialDataCheck()) {
        return this.componentConfig.fallbackTable?.columns;
      }
      if (!this.hasAdditionalData) {
        return this.componentConfig?.columns.filter(column => !this.additionalMetrics.includes(column));
      }
      return this.componentConfig?.columns;
    },
    componentDataSource(): string {
      if (this.componentConfig.fallbackTable && !this.initialDataCheck()) {
        return this.componentConfig.fallbackTable?.dataSource;
      }
      return this.componentConfig?.dataSource;
    },
    containerCSS(): object {
      const css: { 'has-search'?: boolean } = {};
      if (this.componentConfig.searchEnabled) {
        css['has-search'] = true;
      }
      return css;
    },
    lastModifiedDate(): string {
      const date = utils.adDataForKey(this, 'LastModifiedDate');
      return date;
    },
    tableAttributes: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      get(): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const attributes: any = {
          headers: this.tableHeaders,
          items: this.tableData,
          class: 'elevation-0 table-striped',
          additionalData: this.additionalData,
        };
        if (typeof this.componentConfig.footer === 'boolean') {
          if (!this.componentConfig.footer) {
            attributes['hide-default-footer'] = true;
          }
        }
        if (typeof this.componentConfig.sorted === 'boolean') {
          if (!this.componentConfig.sorted) {
            attributes['disable-initial-sort'] = true;
          }
        }

        if (typeof this.componentConfig.perPage === 'number') {
          attributes['items-per-page'] = this.componentConfig.perPage;
          // } else if (typeof this.componentConfig.perPage === 'object') {
          //   attributes['items-per-page'] = this.componentConfig.perPage;
        } else {
          let rowCount = 8;
          if (this.tableData.length > 8) rowCount = 7; // provide space for search bar
          attributes['items-per-page'] = rowCount;
        }

        attributes['custom-sort'] = utils.customSort;
        return attributes;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(newAttrs: any): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const attributes: any = newAttrs;
        return attributes;
      },
    },
    tweaks(): string[] {
      if (!this.componentConfig.commonTweaks) {
        return [];
      }
      if (Array.isArray(this.componentConfig.commonTweaks)) {
        return this.componentConfig.commonTweaks;
      }
      return [this.componentConfig.commonTweaks];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableHeaders(): any[] {
      if (this.tableData.length === 0) {
        return [];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const list: any[] = [];
      let isLast = false;
      let tableKeys = Object.keys(this.tableData[0]);

      if (!(!this.isBeeswax && !this.isSem)) {
        if (tableKeys.includes('Conversions')) {
          const conversionIndex = tableKeys.indexOf('Conversions');
          tableKeys.splice(conversionIndex, 1);
          tableKeys.push('Conversions');
        }
        if (tableKeys.includes('CVR')) {
          const conversionIndex = tableKeys.indexOf('CVR');
          tableKeys.splice(conversionIndex, 1);
          tableKeys.push('CVR');
        }
      }
      if (this.isGeo) {
        const businessNameIndex = tableKeys.indexOf('BusinessName');
        if (businessNameIndex >= 0) {
          tableKeys.splice(businessNameIndex, 1);
          tableKeys.splice(1, 0, 'BusinessName');
        }
      }
      if (this?.tableDataProp?.length) {
        tableKeys = tableKeys.filter(key => this.componentConfig?.columns.includes(key));
      }

      if (!this.noConversionsData && !this.isSem) {
        tableKeys = tableKeys.filter(key => !['Conversions', 'CVR', 'CVR %'].includes(key));
      }

      let tableLength = this.isFilteredByTactic ? tableKeys.length - 1 : tableKeys.length;
      if (this.tweaks.includes('outerMarketColumn')) {
        tableLength += 1;
      }
      if (this.isExporting) {
        tableLength += 1;
        // move tactic to second position
        const tacticIndex = tableKeys.findIndex((k: string) => k === 'Tactic');
        if (tacticIndex >= 0) {
          tableKeys.splice(tacticIndex, 1);
          tableKeys.splice(1, 0, 'Tactic');
        }
      }
      let colWidth = `${92 / tableLength}%`;
      tableKeys.forEach((key, index) => {
        if (key === 'CostPerClick' && this.hideSpend) {
          return;
        }
        // always show tactic column when exporting
        if (key === 'Tactic' && !this.isExporting) {
          // if we show a tactic dropdown, don't show the tactic column
          if (this.isFilteredByTactic) {
            return;
          }
          if (!Object.keys(this.tableData[0]).includes('Tactic')) {
            // should we show the tactic column if we have only one?
            // return;
          }
        }
        if (key.toLowerCase() === 'donotsort') {
          // this is a special column used to make an item always last. We need the value, but not the table column
          return;
        }

        if (index >= tableLength - 1) {
          isLast = true;
        }

        // column width
        let foundHeader = this.componentConfig?.headers?.find(h => h.value === key);
        let headerText = utils.headerNamesMap(key);
        if (!foundHeader && key === 'Tactic') {
          foundHeader = { width: '20%', text: 'Tactic' };
        }
        if (foundHeader) {
          colWidth = foundHeader?.width;
          if (foundHeader.text) {
            headerText = foundHeader.text;
          }
        } else if (this.componentConfig.widths) {
          colWidth = this.componentConfig.widths[index];
        } else {
          colWidth = isLast ? '8%' : colWidth;
        }

        if (this.componentConfig.title === 'Geofencing Locations') {
          if (key === 'GeoFenceName') {
            colWidth = '40%';
          } else {
            colWidth = '23%';
          }
        }

        if (key === 'outerMarketImps' && this.tweaks.includes('outerMarketColumn')) {
          list.push({
            text: 'Outer Market Imps',
            align: 'left',
            sortable: true,
            value: 'outerMarketImps',
            width: colWidth,
          });
          return;
        }
        // Hide ClickThrough and Clicks if we have no/empty data for them
        if (!this.filteredEmptyMetrics.includes('CTR') && !this.filteredEmptyMetrics.includes('ClickThrough')) {
          if (key === 'CTR' || key === 'ClickThrough') {
            return;
          }
        }
        if (!this.filteredEmptyMetrics.includes('Clicks')) {
          if (key === 'Clicks') {
            return;
          }
        }

        // DASH-4344: filter out innovid xp data on ott campaigns
        if (
          !this.filteredEmptyMetrics.includes('ResponseNumber') &&
          !this.filteredEmptyMetrics.includes('ResponseRate') &&
          !this.filteredEmptyMetrics.includes('ResponseShare') &&
          !this.filteredEmptyMetrics.includes('Index')
        ) {
          if (key === 'ResponseNumber' || key === 'ResponseRate' || key === 'ResponseShare' || key === 'Index') {
            return;
          }
        }

        // DASH-3758: replace Zip Code for OTT campaigns
        if (key === 'ZipCode' && this.$store.state.customer.currentNavTab === 'ott') {
          list.push({
            text: 'Zip/Postal Code',
            align: 'left',
            sortable: true,
            value: key.replace(/\s/g, ''),
            width: colWidth,
          });
          return;
        }
        list.push({
          text: headerText,
          align: 'left',
          sortable: true,
          value: key.replace(/\s/g, ''),
          width: colWidth,
        });
      });

      return list;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rangeData(): any {
      if (this.tableData && this.categorySelected) {
        const cat = this.categorySelected;
        // find max and min
        const max = Math.max.apply(
          null,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.tableData.map((a: any) => {
            return a[cat];
          }),
        );
        const min = Math.min.apply(
          null,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.tableData.map((a: any) => {
            return a[cat];
          }),
        );
        const logNum = Math.ceil(getBaseLog(3, max));
        const returnArr = [0];
        if (min < 2) {
          returnArr.push(1);
        }
        for (let n = logNum; n > 0; n--) {
          const b = 3 ** (logNum - n); //  Math.pow(3, logNum - n);
          if (min > b) {
            // don't push anything
          } else returnArr.push(b);
        }
        return returnArr;
      }
      return [0, 1, 5, 10, 25, 50, 100];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableDataSource(): any {
      const originalArray = utils.adDataForKey(this, this.componentDataSource);
      if (originalArray?.length) {
        const newArray = originalArray.map(item => ({ ...item }));
        return newArray;
      }
      return originalArray;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData(): any {
      if (this?.tableDataProp?.length) {
        return this.tableDataProp;
      }
      let rows = utils.ensureArray(this.tableDataSource);
      let shouldSort = false; // hack related to a data issue 1/3/2022. If we detect a "Null" value, we need to sort it to the bottom
      let combinedOther: Campaign;
      if (!rows || !Array.isArray(rows)) {
        return [];
      }

      if (this.tweaks.includes('outerMarketColumn')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.map((x: any) => {
          if (typeof x.HHImpressions === 'number') {
            switch (x.League) {
              case 'NHL':
                x.outerMarketImps = utils.formatNumberWithCommas(Math.round(x.HHImpressions * 1.94));
                break;
              case 'MLB':
                x.outerMarketImps = utils.formatNumberWithCommas(Math.round(x.HHImpressions * 2.0));
                break;
              case 'NBA':
                x.outerMarketImps = utils.formatNumberWithCommas(Math.round(x.HHImpressions * 1.8));
                break;
              default:
                break;
            }
            return x;
          }
        });
      }
      // Inserts 'N/A' when 'Order/EstimateNumber', 'ISCICode', 'Team', or 'League'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rows = rows.map((x: any) => {
        if (typeof x.EstimateNumber === 'undefined' || x.EstimateNumber === null) {
          x.EstimateNumber = 'N/A';
        }
        if (typeof x.ISCICode === 'undefined' || x.ISCICode === null) {
          x.ISCICode = 'N/A';
        }
        if (typeof x.Team === 'undefined' || x.Team === null) {
          x.Team = 'N/A';
        }
        if (typeof x.League === 'undefined' || x.League === null) {
          x.League = 'N/A';
        }
        if (x.ResponseRate && typeof x.ResponseRate === 'number') {
          x.ResponseRate = `${x.ResponseRate}%`;
        }
        if (x.ResponseShare && typeof x.ResponseShare === 'number') {
          x.ResponseRate = `${x.ResponseRate}%`;
        }
        return x;
      });
      rows = rows.reduce((allCampaigns, campaign: Partial<Campaign>) => {
        // ugly hack 1/3/2022 to temporarily deal with a data issue where values are returning as "Null, Null"
        if (
          (campaign.SearchKey &&
            (campaign.SearchKey.toLowerCase() === 'null, null' || campaign.SearchKey.toLowerCase() === 'other')) ||
          (campaign.CityId &&
            (campaign.CityId.toLowerCase() === 'null, null' || campaign.CityId.toLowerCase() === 'other')) ||
          (campaign.City &&
            (campaign.City.toLowerCase().includes('null') || campaign.City.toLowerCase() === 'other')) ||
          (campaign.Metro &&
            (campaign.Metro.toLowerCase().includes('null') || campaign.Metro.toLowerCase() === 'other')) ||
          (campaign.ZipCode &&
            (campaign.ZipCode.toLowerCase().includes('null') || campaign.ZipCode.toLowerCase() === 'other')) ||
          (campaign.State &&
            (campaign.State.toLowerCase().includes('null') || campaign.State.toLowerCase() === 'other'))
        ) {
          // we have a "null" or "other" campaign
          shouldSort = true;
          combinedOther = utils.combineMetrics(combinedOther, campaign);
          if (combinedOther.CityId) combinedOther.CityId = 'Other';
          if (combinedOther.City) combinedOther.City = 'Other';
          if (combinedOther.Metro) combinedOther.Metro = 'Other';
          if (combinedOther.SearchKey) combinedOther.SearchKey = 'Other';
          if (combinedOther.ZipCode) combinedOther.ZipCode = 'Other';
          if (combinedOther.State) combinedOther.State = 'Other';
        } else {
          // anything else that's not a null or "other" campaign
          allCampaigns.push(campaign);
        }

        return allCampaigns;
      }, []); // shallow clone, for transforms to stay localized to this copy of the data
      if (combinedOther) {
        // we have a "null" or "other" campaign
        rows.push(combinedOther);
      }

      if (this.componentDataSource === 'AUDIO.ByDeviceImpression') {
        // for now just rename 'UNKNOWN' to 'Other'

        if (rows.find(this.findDevice('UNKNOWN'))) {
          rows.find(this.findDevice('UNKNOWN')).Device = 'Other';
        }
      }

      // convert CPC value, if SEM.BySearchImpression presented (better to convert on server side)
      if (this.componentDataSource === 'SEM.BySearchImpression') {
        rows = rows.map(row => {
          if (!(typeof row.CPC === 'string')) row.CPC = `$${row.CPC.toFixed(2)}`;
          return row;
        });
      }

      if (this.componentDataSource === 'SEM.ByConversion') {
        rows = rows.map(row => {
          row.Conversions = (Math.round(row.Conversions * 100) / 100).toFixed(2);
          return row;
        });
      }
      if (shouldSort) {
        // Part of the hack above to sort the "Null" values to the bottom. Uses a default "Impressions" to sort by if no other column is selected
        rows = utils.customSort(rows, this.componentConfig.sortBy || 'Impressions', [true]);
      }
      if (this.componentColumns) {
        let columns = [...this.componentColumns];

        // very specific hack bc migrated campaigns are using dif casing for 'Zipcode'
        // hopefully can delete this when were fully migrated
        if (rows?.length > 0 && Object.keys(rows[0])?.includes('ZipCode')) {
          const filtered = columns.filter(c => c !== 'Zipcode');
          columns = ['ZipCode', ...filtered];
        }
        // DASH-3145, sometimes BE is returning ClickThrough as CTR, should be fixed on their end later
        // DASH-3303: skip for OTT.ByZipImpression
        // DASH-3495: skip for OTT.GeoPerformanceImpression
        if (
          rows?.length > 0 &&
          Object.keys(rows[0])?.includes('CTR') &&
          columns.includes('ClickThrough') &&
          this.dataSource !== 'OTT.ByZipImpression' &&
          this.dataSource !== 'OTT.GeoPerformanceImpression' &&
          !this.isXandr
        ) {
          const filtered = columns.filter(c => c !== 'ClickThrough');
          columns = [...filtered, 'CTR'];
        }

        if (this.hideSpend) {
          columns = utils.filterOutColumns(columns, ['CostPerClick']);
        }
        // hide Clicks and CTR for GAM Video
        if (this.componentConfig.dataChecks === 'HasGAMVIDEO') {
          columns = utils.filterOutColumns(columns, ['Clicks', 'ClickThrough']);
        }
        if (this.tweaks.includes('outerMarketColumn')) {
          columns.push('outerMarketImps');
        }
        const errorReport = new Set();
        // remove columns that aren't in the columns
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.map((x: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const c: any = {};
          columns.forEach((col: string) => {
            if (typeof col === 'string' && col.length > 0) {
              c[col] = x[col];
              // console.log(`col is ${col}, and c[col] is ${c[col]}`);
              if (!c[col]) {
                errorReport.add(col);
              }
              if (col === 'Product') {
                c[col] = this.getProductName(c[col]);
              }
            }
          });
          return c;
        });
        if (errorReport.size > 0) {
          // eslint-disable-next-line no-console
          // console.log(`Table.DataSource: ${this.dataSource} Missing: ${[...errorReport]}`);
        }
      }

      if (this.tweaks.includes('daybackfill')) {
        const week: object[] = [];
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].forEach(d => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const day = rows.find((x: any) => x.Day === d);
          if (day) {
            week.push(day);
          } else {
            week.push({
              Day: d,
              Aired: 0,
              Visits: 0,
              VisitsAiring: 0,
            });
          }
        });
        rows = week;
      }
      if (this.tweaks.includes('hourbackfill')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.map((x: any) => {
          const Hour = utils.formatHour(parseInt(x.Hour, 10), false);
          return { ...x, Hour };
        });
      }
      if (this.tweaks.includes('daypartbackfill')) {
        let dayparts = ['Morning', 'MidDay', 'Afternoon', 'Night', 'Overnight'];
        if (this.isSimplifi) {
          dayparts = ['12am to 4am', '4am to 8am', '8am to 12pm', '12pm to 4pm', '4pm to 8pm', '8pm to 12am'];
        }
        const tableData = [];
        dayparts.forEach((dayPartKey: string) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const dayPart = rows.find((x: any) => x.Daypart === dayPartKey);
          if (dayPart) {
            tableData.push(dayPart);
          }
        });
        rows = tableData;
      }
      if (this.componentConfig.transforms) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.map((x: any) => {
          Object.keys(this.componentConfig.transforms).forEach((key: string) => {
            let transforms = this.componentConfig.transforms[key];
            if (transforms && !Array.isArray(transforms)) {
              if (typeof transforms === 'string') {
                transforms = [transforms];
              } else {
                transforms = null;
              }
            } else {
              transforms = null;
            }
            if (!transforms) {
              return;
            }
            let value = x[key];
            transforms.forEach((t: string) => {
              switch (t) {
                case 'percents':
                  value = `${value}%`;
                  break;
                case 'standardTime':
                  value = utils.formatHour(value, false);
                  break;
                default:
                  break;
              }
            });
            x[key] = value;
          });
          return x;
        });
      }
      // filter out, for example, aired < 2
      if (this.componentConfig.filter) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.filter((i: any) => {
          if (this.gtltSelected === 'greater') {
            return i[this.categorySelected.toString()] > this.filterNumber;
          } // else return less than
          return i[this.categorySelected.toString()] < this.filterNumber;
        });
      }
      // set size limit based on customization settings
      if (this.componentConfig?.itemLimit && parseFloat(this.componentConfig.itemLimit) > 0) {
        rows = rows.slice(0, parseFloat(this.componentConfig.itemLimit));
      }
      if (!this.isFilteredByTactic || this.isExporting) return rows;
      // filter by tactic, setting the filtered list with first tactic in the list we find.
      if (!this.selectedTacticFilter) this.setTacticFilter(rows[0]?.Tactic);
      return rows.filter(i => i.Tactic === this.selectedTacticFilter);
    },
    filteredEmptyData() {
      const data = this.tableData;
      const newData = [];
      data.forEach(dataObj => {
        const cleanObj = Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          Object.entries(dataObj).filter(([_, v]) => Boolean(this.getFloatFromValue(v))),
        );
        newData.push(cleanObj);
      });
      return newData;
    },
    filteredEmptyMetrics() {
      // this creates an array of valid metrics, i.e. metrics without all null/undefined/0 values
      return Object.keys(Object.assign({}, ...this.filteredEmptyData));
    },
    tacticFilterList(): Array<string> | null {
      if (!this.isFilteredByTactic) return null;
      return utils.getSetOfTactics(this, this.componentConfig);
    },
    showAdPreview(): boolean {
      return this.componentDataSource === 'SEM.CreativeList';
    },
    hideSpend(): boolean {
      // TODO: support multiple campaigns
      const campaignId = this.$store.state.filters.selectedCampaign?.id;
      const details = utils.campaignDetailsById(this)[campaignId];
      return details?.HideSpend;
    },
    isPublisherTable(): boolean {
      return this.componentColumns.includes('Publisher');
    },
    isIconTable(): boolean {
      return this.componentConfig?.icon;
    },
    // isFilteredByTactic(): boolean {
    //   return this.componentConfig.filterBy && this.componentConfig.filterBy.includes('Tactic');
    // },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.tableData?.length > 0 || this.componentConfig?.filter;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData) || this.isPrinting) {
        return false;
      }
      return true;
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
    feedSources(): Array<string> {
      const feeds = utils.feedSources(this);
      return feeds;
    },
    isBeeswax(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('BEESWAX');
    },
    isXandr(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('XANDR');
    },
    isSem(): boolean {
      return this.isExporting
        ? this.exportData.tab.toLowerCase() === 'sem'
        : this.$store.state?.customer?.currentNavTab?.toLowerCase() === 'sem';
    },
    isSimplifi(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('SIMPLIFI');
    },
    hasCustomizedFeedSource(): boolean {
      return this.componentConfig?.feedSource?.length || this.componentConfig?.feedSourceToExclude?.length;
    },
    validFeedSource(): boolean {
      if (!this.componentConfig?.feedSource?.length && !this.componentConfig?.feedSourceToExclude?.length) return true;
      if (this.componentConfig?.feedSourceToExclude?.length) {
        return !this.componentConfig.feedSourceToExclude.some(item => this.feedSources.includes(item));
      }
      if (this.componentConfig?.feedSource?.length) {
        return this.componentConfig.feedSource.some(item => this.feedSources.includes(item));
      }
      return false;
    },
    isGeo(): boolean {
      const forExporting =
        this.exportData?.tab.toLowerCase() === 'gtdisplay' || this.exportData?.tab.toLowerCase() === 'gtvideo';
      const forCurrentTab =
        this.$store.state?.customer?.currentNavTab?.toLowerCase() === 'gtdisplay' ||
        this.$store.state?.customer?.currentNavTab?.toLowerCase() === 'gtvideo';
      return this.isExporting ? forExporting : forCurrentTab;
    },
    noConversionsData(): boolean {
      return this?.tableData?.length > 0 && this?.tableData?.some(row => row.Conversions);
    },
  },
  mounted() {
    if (this.componentConfig.filter) {
      this.filterNumber = this.componentConfig.filter[2];
      this.categorySelected = this.componentConfig.filter[0][0];
      this.categories = this.componentConfig.filter[0];
    }
    if (typeof this.componentConfig.perPage === 'number') {
      this.renderKey = [this.componentConfig.perPage];
    } else if (typeof this.componentConfig.perPage === 'object') {
      this.renderKey = this.componentConfig.perPage;
    }
    this.isFilteredByTactic = this.componentConfig.filterBy && this.componentConfig.filterBy.includes('Tactic');
    setTimeout(() => {
      if (!this.hasEnoughData) {
        this.$emit('rendered', { empty: true });
      }
    }, 500);
  },
  created() {
    this.storedAttributes = this.tableAttributes;
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRendered(context: any): void {
      if (this.validFeedSource) {
        this.$emit('rendered', context);
      } else {
        this.$emit('rendered', { empty: true });
      }
    },
    setTacticFilter(tactic: string): void {
      this.selectedTacticFilter = tactic;
    },
    creativeType(index: number): string {
      if (index > 0) return '';
      return this.componentConfig?.creativeType;
    },
    isIcon(type: string): boolean {
      return this.componentColumns.includes(type);
    },
    getIcon(value: string): string {
      const icon = utils.iconLibrary[value.toLowerCase()];
      return icon || '';
    },
    getTacticName(tactic: string): string {
      return utils.getTacticName(tactic);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    findDevice(name: string): any {
      return device => device.Device === name;
    },
    getProductName(key: string): string {
      let name;
      switch (key) {
        case 'GeoRetargeting':
          name = `Retargeting Audience`;
          break;
        case 'GeoBehavior':
          name = `Behavior Audience`;
          break;
        case 'GeoCookie':
          name = `Location Audience`;
          break;
        default:
          name = key;
          break;
      }
      return name;
    },
    initialDataCheck(): boolean {
      return !!utils.adDataForKey(this, this.componentConfig.dataSource);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFloatFromValue(value: any): number {
      return parseFloat(String(value).replace(/[^a-zA-Z0-9 ]/g, ''));
    },
  },
  watch: {
    tableDataSource: {
      handler(): void {
        this.selectedTacticFilter = null;
      },
    },
    tableData: {
      handler(): void {
        this.isFilteredByTactic = this.componentConfig.filterBy && this.componentConfig.filterBy.includes('Tactic');
        // hide right away if incorrect feed source
        // show if in edit mode whatever the data is
        // show if there is data, hide if not + hideIfNoData in component config is true
        if (this.hasCustomizedFeedSource && !this.validFeedSource) {
          this.$emit('set-display', false);
          return;
        }
        if (this.$store.state.layoutEditor.editMode) {
          this.$emit('set-display', true);
          return;
        }
        if (!this.tableData.length && this.componentConfig.hideIfNoData) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
  },
});
