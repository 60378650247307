
import Vue from 'vue';
import { validProducts, renderComponent, getTopTacticData, getTopTactics, topTacticGroupings } from './utils';
import { TopTacticGroupings, TopTacticsWithMetrics, TopTacticMetric } from './types';
import { CurrentSection } from '../../../types/layout';
import MapWrapper from './../maps/mapWrapper.vue';
import CampaignList from '../summary/components/campaignList.vue';
import MapSummaryOverlay from './layouts/mapSummaryOverlay.vue';

export default Vue.extend({
  name: 'SummaryController',
  inheritAttrs: false,
  data: (): {
    componentName: string | null;
    config: object;
    advertiserTotalSummary: object,
    loading: boolean,
  } => ({
    componentName: null, // get from theme. set a default
    config: {
      legend: null,
      loading: true,
      loadedValidData: true,
      dataCacheKey: null,
      dataSource: null,
      tweaks: {},
      campaignDetails: null,
      mapEnabled: false,
      hoveredLayer: null,
      tooltipTitle: null,
      tooltipContent: null,
      layoutThemes: [],
      showTopAlert: true,
    },
    advertiserTotalSummary: {},
    loading: true
  }),
  // include all summary layouts
  components: { MapWrapper, CampaignList, MapSummaryOverlay },
  props: [
    'commonTweaks',
    'componentConfig',
    'dataSource',
    'headers',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'sectionConfig',
    'title',
    'topOptions',
  ],
  created() {
    this.componentName = this.Theme?.config?.summary?.layout; // get from theme set a default
    this.readConfig();
  },
  mounted() {
    if (!this.isExporting || this.isPrinting) {
      this.$store.dispatch('summary/resetTopTactics').then(() => {
        this.getAdvertiserTotalSummary();
      });
    } else if (this.isXLS) {
      // wait for overlay render event
    } else if (!this.hasMapData && this.hasValidSummaryData) {
      setTimeout(() => {
        this.$emit('rendered', { empty: false });
      }, 100);
    } else if (!this.hasValidSummaryData) {
      setTimeout(() => {
        this.$emit('rendered', { empty: true });
      }, 5000);
    }
  },
  watch: {
    disabledProducts: {
      handler(): void {
        this.getAdvertiserTotalSummary();
      },
    },
  },
  methods: {
    getMapHeight(): number {
      const defaultHeight = 450;
      const extraHeight = 40;
      const summaryThreshold = 410;
      const configHeight = this.componentConfig?.size?.height ?? defaultHeight;

      return this.summaryHeight > summaryThreshold ? Math.max(this.summaryHeight + extraHeight, configHeight) : configHeight;
    },
    setMapHeight(): void {
      this.$store.dispatch('summary/setMapHeight', this.getMapHeight());
    },
    async getAdvertiserTotalSummary() {
      const products = await this.fetchProducts();

      this.$store.dispatch('getAdvertiserTotalSummary', products, this.isShared).then(response => {
        this.advertiserTotalSummary = Object.keys(response)
          .filter(key => !this.disabledProducts.includes(key))
          .reduce((obj, key) => {
            obj[key] = response[key];
            return obj;
        }, {});

        this.setGroupedTopTacticData();
      }).finally(() => this.loading = false);
    },
    async fetchProducts() {
      const productsResponse = await this.$store.dispatch('getProductsForListOnSummary', {
        advertiserId: this.$route.query?.id || '88888888',
        daterange: 'alltime',
        isShared: this.isShared
      });
      const productsList = productsResponse?.CampTypes;
      return productsList;
    },
    renderComponent(cpnt: string): boolean {
      return renderComponent(cpnt, this.componentConfig.subComponent, this.isExporting & !this.isPrinting);
    },
    setGroupedTopTacticData(): void {
      const groupings = topTacticGroupings.reduce((all: Array<object>, grouping: TopTacticGroupings) => {
        // loop through groups of tactics and match with available summary data
        const tacticData: Array<TopTacticsWithMetrics> = grouping.tactics.reduce(
          (all: Array<TopTacticsWithMetrics>, tacticId: string) => {
            const tacticGroup = getTopTactics().find(x => x.id === tacticId);

            // dont include tactics that have been disabled
            if (tacticGroup && this.summaryDataNew && !this.disabledProducts?.includes(tacticGroup.id)) {

              const data = getTopTacticData(this.summaryDataNew, tacticGroup);
              if (data) all.push(data);
            }
            return all;
          },
          [],
        );

        if (!tacticData.length) return all; // exit if no tactics are available
        const metricCount = tacticData[0]?.metrics?.length;
        const headers = tacticData[0].metrics.map((metric: TopTacticMetric, index: number) => {
          // setting widths of columns if no map.
          if (this.hideMap) {
            if (metric.headerName === 'Product')
              // set Product tab to always be 25%
              return { text: metric.headerName, value: metric.dataKey, width: '25%' };
            else if (metricCount === 3 && index === 2)
              return { text: metric.headerName, value: metric.dataKey, width: '50%' };
            else if (metricCount === 2) return { text: metric.headerName, value: metric.dataKey, width: '75%' };
            else return { text: metric.headerName, value: metric.dataKey, width: '25%' };
          } else return { text: metric.headerName, value: metric.dataKey };
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tactics = tacticData.reduce((all: any[], tactic: any) => {
          const tacticObj = {
            slug: tactic.slug,
          };
          tactic.metrics.forEach(m => {
            tacticObj[m.dataKey] = m.value;
          });
          all.push(tacticObj);
          return all;
        }, []);

        // key is used for the v-for loop)
        all.push({ key: grouping.tactics.join(''), tactics, headers });
        return all;
      }, []);

      this.$store.dispatch('summary/setGroupedTopTactics', groupings);
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    readConfig(): void {
      this.config.layoutThemes = this.componentConfig?.themes;
      this.config.dataSource = 'SummaryAPI';
      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''} ${this.$route.query.tab || ''}`;

      this.config.dataCacheKey = `${this.config.dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');
      this.config.mapEnabled = true;
      if (this.componentConfig?.mapTweaks === 'hideMap') {
        this.config.mapEnabled = false;
      }
      this.config.loading = false;
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    disabledProducts(): Array<String> {
      return this.componentConfig?.disabledProducts;
    },
    summaryHeight(): number {
      return this.$store.state?.summary?.dimensions?.summaryHeight || 0;
    },
    renderSummaryPage(): boolean {
      return this.hasValidSummaryDataNew && this.config.mapEnabled;
    },
    hasValidSummaryData(): boolean {
      let valid = false;
      if (!this.summaryData) {
        return valid;
      }
      for (const product of validProducts) {
        if (this.summaryData[product]) {
          valid = true;
          break;
        }
      }

      return valid;
    },
    hasValidSummaryDataNew(): boolean {
      let valid = false;
      if (!this.summaryDataNew) {
        return valid;
      }
      for (const product of validProducts) {
        if (this.summaryDataNew[product]) {
          valid = true;
          break;
        }
      }

      return valid;
    },
    summaryData(): object {
      let summaryData = this.$store.state.customer?.summaryPerformance;
      if (!summaryData?.loaded && (this.isExporting && !this.isPrinting) && this.exportData?.summaryData?.pstate) {
        summaryData = { ...this.exportData.summaryData, loaded: true };
      }
      if (!summaryData?.loaded && this.isShared && this.$store.state.customer?.sharedSelection?.adData) {
        summaryData = this.$store.state.customer.sharedSelection.adData;
      }
      return summaryData;
    },
    summaryDataNew(): object {
      type SummaryValue = { [key: string]: any };

      let summaryData = Object.fromEntries(
        Object.entries(this.advertiserTotalSummary).map(([key, value]: [string, SummaryValue]) => [
          key,
          {
            ...value,
            AllowSingleCampaign: true,
            TagName: key
          }
        ])
      );

      if (!summaryData?.loaded && (this.isExporting && !this.isPrinting) && this.exportData?.summaryData?.pstate) {
        summaryData = { ...this.exportData.summaryData, loaded: true };
      }
      return summaryData;
    },
    hasRequiredData(): boolean {
      if ((this.isExporting && !this.isPrinting) && this.componentConfig.pretendError) {
        return false;
      }
      return !!(this.config.dataSource && this.config.dataCacheKey);
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hasMapData(): any {
      const hasMapData = !!(
        (this.summaryData?.DISPLAY?.DMAList && this.summaryData?.DISPLAY?.DMAList?.length) ||
        (this.summaryData?.DISPLAY?.GeoList && this.summaryData?.DISPLAY?.GeoList?.length) ||
        (this.summaryData?.PREROLL?.DMAList && this.summaryData?.PREROLL?.DMAList?.length) ||
        (this.summaryData?.PREROLL?.GeoList && this.summaryData?.PREROLL?.GeoList?.length) ||
        (this.summaryData?.OTT?.DMAList && this.summaryData?.OTT?.DMAList?.length) ||
        (this.summaryData?.OTT?.GeoList && this.summaryData?.OTT?.GeoList?.length) ||
        (this.summaryData?.VIDEO?.DMAList && this.summaryData?.VIDEO?.DMAList?.length) ||
        (this.summaryData?.VIDEO?.GeoList && this.summaryData?.VIDEO?.GeoList?.length) ||
        (this.summaryData?.LINEAR?.GeoList && this.summaryData?.LINEAR?.GeoList?.length) ||
        (this.summaryData?.GAMVIDEO?.GeoList && this.summaryData?.GAMVIDEO?.GeoList?.length) ||
        (this.summaryData?.BROADCAST?.GeoList && this.summaryData?.BROADCAST?.GeoList?.length) ||
        (this.summaryData?.SEM?.GeoList && this.summaryData?.SEM?.GeoList?.length) ||
        (this.summaryData?.SOCIAL?.GeoList && this.summaryData?.SOCIAL?.GeoList?.length) ||
        (this.summaryData?.GAMDISPLAY?.GeoList && this.summaryData?.GAMDISPLAY?.GeoList?.length) ||
        (this.summaryData?.TRUGEOFENCE?.GeoList && this.summaryData?.TRUGEOFENCE?.GeoList?.length)
      );
      return hasMapData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
  },
});
